import { useState } from "react";
import { AuthWrapper } from "../components/AuthWrapper";
import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Link,
  Divider,
} from "@chakra-ui/react";

import http from "../connection/connect";

import { navigateToUserStore } from "../helpers/global";

const Login = () => {
  const [isLoading, setLoading] = useState(false);

  //form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //error state
  const [err, setError] = useState("");

  //form values
  const formValues = {
    email,
    password,
  };

  //form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await http.post("api/auth/login", formValues);
      if (response.data.status === 200) {
        setLoading(false);
        await localStorage.setItem("userData", JSON.stringify(response.data));
        if (window.location.origin.includes("storak.local")) {
          const userAction = window.confirm(
            "Would you like to redirect to current react app ?"
          );
          if (userAction) {
            window.location.href = window.location.origin;
            return;
          }
        }
        navigateToUserStore();
      } else {
        setLoading(false);
        setError(response.data.msg);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        setError(error.response.data.message);
      } else {
        setLoading(false);
        setError("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <AuthWrapper header="Login">
      <Divider />
      <form onSubmit={handleSubmit}>
        <FormControl pt={4}>
          <Input
            mt={5}
            id="email"
            placeholder="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Input
            mt={5}
            id="password"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            isLoading={isLoading}
            loadingText="Loading.."
            type="submit"
            width="100%"
            mt={5}
          >
            Login
          </Button>

          <Center>
            <Box color="red" mt={3}>
              {err}
            </Box>
          </Center>
          <Center mt={3}>
            <Link href="/register" textAlign="center">
              Don't have an account? Register
            </Link>
          </Center>
          <Center>
            <Link href="/forgot-password">Forgot Password?</Link>
          </Center>
        </FormControl>
      </form>
    </AuthWrapper>
  );
};

export default Login;
